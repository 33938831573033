#ReNew {
  overflow: hidden;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: var(--padding);
  gap: calc(var(--gap) * 1.5);
}

/* HEADER */

#ReHeader {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: "undo logo reset";
  align-items: center;
  justify-items: center;
  height: fit-content;
}

#ReHeader > img {
  --header-height: 30px;
  height: var(--header-height);
}

#ReHeader > img.logo {
  grid-area: logo;
}

#ReHeader > img.reset {
  grid-area: reset;
  margin-left: auto;
}

#ReHeader > img.undo {
  grid-area: undo;
  margin-right: auto;
}

#ReHeader > img.reset,
#ReHeader > img.undo {
  background-color: var(--metin2-background);
  border-radius: var(--radius);
  box-sizing: border-box;
  padding: var(--padding);
  cursor: pointer;
}

/* BODY */

#ReBody {
  display: grid;
  --cell-width: 1fr;
  grid-template-columns: repeat(5, var(--cell-width));
  grid-template-rows: repeat(5, var(--cell-width));
  grid-gap: calc(var(--gap) / 1);
  overflow: hidden;
}

#ReBox {
  position: relative;
  border-radius: calc(var(--radius) / 2);
  padding: var(--padding);
  aspect-ratio: 1/1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  /* user-select: none; */
  overflow: hidden;
}

#ReBox > .selected {
  position: absolute;
  z-index: 9;
  top: -10px;
  left: -10px;
  bottom: -10px;
  right: -10px;

  background-color: rgba(0, 0, 0, 0.75);
  animation: fadeIn 0.1s;
  z-index: 10;
}

.RePositionName {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0);
  font-size: medium;
  user-select: none;
  opacity: 0.8;
  user-select: none;
}

.ReBoxNumber {
  font-size: xx-large;
  text-shadow: 0px 0px 3px white, 0 0 3px white, 0 0 3px white;
  user-select: none;
}

.numbersColumn,
.colorsColumn {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  width: 15%;
  height: 100%;
  display: none !important;
  flex-direction: column;
  align-items: center;
  user-select: none;
}

.colorsColumn {
  left: 0;
  right: unset;
}

.numbersColumn > span,
.colorsColumn > span {
  height: 15%;
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  background-color: var(--metin2-background);
}

.colorsColumn > span {
  border-left: unset !important;
  border-bottom: unset !important;
  border-right: 1px solid black;
}

.numbersColumn > span:last-child,
.colorsColumn > span:last-child {
  border-bottom: unset;
}

/* FOOTER */

#ReFooter {
  max-height: 40%;
  overflow: hidden;
  display: grid;
  grid-template-areas:
    "RePalette RePalette"
    "ReChoices ReChoices"
    "ReStatistics ReStatistics";
  grid-template-columns: 8fr 2fr;
  gap: calc(var(--gap) * 1.5);
}

#RePalette {
  grid-area: RePalette;
}
#ReChoices {
  grid-area: ReChoices;
}

#RePalette,
#ReChoices {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: var(--gap);
}

#ReChoices > span {
  background-color: var(--metin2-background);
}

#RePalette > span,
#ReChoices > span {
  width: 100%;
  aspect-ratio: 1;
  border-radius: calc(var(--radius) / 2);

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: xx-large;

  cursor: pointer;
  user-select: none;
}

#ReStatistics {
  grid-area: ReStatistics;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr 1fr;
  gap: var(--gap);
}

#Stat {
  display: flex;
  flex-direction: row;
  padding: calc(var(--padding) * 0.25) var(--padding);
  align-items: center;
  justify-content: space-between;
  background-color: var(--metin2-background);
  font-size: xx-large;
  border-radius: calc(var(--radius) * 0.5);
  user-select: none;
  box-sizing: border-box;
}

#Stat > img {
  --card-height: 30px;
  box-sizing: border-box;
  height: var(--card-height);
}

@media (min-width: 900px) {
  .numbersColumn,
  .colorsColumn {
    display: flex !important;
  }

  .numbersColumn > span {
    height: 14%;
    font-size: 0.9rem;
  }

  #ReNew {
    display: grid;
    gap: var(--gap);
    grid-template-areas:
      "ReHeader ReFooter"
      "ReBody ReFooter";
    grid-template-rows: 1fr 9fr;
    grid-template-columns: 2fr 1fr;
  }

  #ReHeader {
    grid-area: ReHeader;
    height: 100%;
    overflow: hidden;
    grid-template-columns: repeat(5, 1fr);
    grid-template-areas: "logo . . undo reset";
  }

  #ReHeader > img {
    --header-height: 60px;
  }
  #ReHeader > img.reset {
    margin-left: unset;
  }

  #ReBody {
    grid-area: ReBody;
    --cell-width: 110px;
    margin: unset;
  }

  #ReFooter {
    grid-area: ReFooter;
    max-height: unset;

    grid-template-columns: 1fr 1fr 2fr;
    grid-template-rows: 1fr 10%;
    grid-template-areas:
      "RePalette ReChoices ReStatistics"
      "ReHandlers ReHandlers ReStatistics";

    gap: unset;
  }

  #ReStatistics,
  #RePalette,
  #ReChoices {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap);
    overflow: hidden;
    box-sizing: border-box;
  }

  #RePalette,
  #ReChoices {
    height: 100%;
    padding: var(--padding);
  }

  #ReStatistics {
    padding: var(--padding);
  }

  #Stat {
    min-width: 50%;
  }
}

@media (min-width: 1365px) {
  #ReBody {
    --cell-width: 120px;
  }
  .ReBoxNumber {
    font-size: 4rem;
  }

  .numbersColumn > span {
    font-size: 1rem;
  }
}

@media (min-width: 1900px) {
  #ReBody {
    --cell-width: 150px;
  }

  .ReBoxNumber {
    font-size: 5rem;
  }
  .numbersColumn > span {
    font-size: 1.2rem;
  }
}
