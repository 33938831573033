/* #OkeyNew {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  overflow: hidden;
  height: 100%;
  background-color: red;
}

#OkeyField {
  display: grid;
  padding: var(--padding);
  align-self: center;
  grid-auto-flow: column;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(8, 1fr);
  grid-gap: var(--gap);
}

#OkeyCard {
  cursor: pointer;
  position: relative;
  border-radius: var(--radius);
  text-shadow: 0 0 15px #fff;
  overflow: hidden;
  height: 9rem;
  width: 6.5rem;
  font-size: 6rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

#OkeyCombinations {
  height: 100%;
  overflow-y: auto;
  color: white;
}

#OkeyScore {
  font-size: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: var(--margin);
}

#OkeyScore > #OkeyCard {
  color: black;
  height: 4rem;
  width: 3rem;
  font-size: unset;
  margin-right: var(--margin);
}

#OkeyScore > :last-child {
  margin-left: auto;
  margin-right: var(--margin);
}

#OkeyCross {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
}

#OkeyFooter {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}


@media (min-width: 950px) {
  #OkeyField {
    grid-auto-flow: row;
    align-self: unset;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(8, 1fr);
  }
  #OkeyCard {
    height: 12rem;
    width: 9rem;
    font-size: 8rem;
  }

  #OkeyFooter {
    right: unset;
    left: 0;
    width: unset;
  }
} */

#Okey {
  display: grid;
  grid-template-areas:
    "OkeyField"
    "OkeyCombinations";
  overflow: hidden;
  font-family: "PTSans", "Trebuchet MS", "Helvetica Neue", "Nimbus Sans", "Helvetica", "Arial", sans-serif;
}

#OkeyField {
  grid-area: OkeyField;

  display: grid;
  grid-template-columns: repeat(8, 1fr);
  padding: var(--padding);
  grid-gap: var(--gap);
  align-self: center;
}

#OkeyCombinations {
  grid-area: OkeyCombinations;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: var(--padding);
  padding-bottom: var(--padding);
}
#OkeyCombinations::-webkit-scrollbar {
  display: none;
}

#OkeyCombinations > h1 {
  font-size: x-large;
  text-align: center;
  color: var(--clear);
  /* position: fixed; */
}

#OkeyScore {
  margin-bottom: var(--margin);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: var(--gap);
  padding: var(--padding);
  align-items: center;
}

#OkeyScore > #OkeyCard {
  font-size: 5rem;
}

#OkeyScore > :last-child {
  text-align: center;
  font-size: xx-large;
  color: var(--clear);
}

#OkeyCard {
  cursor: pointer;
  position: relative;
  aspect-ratio: 2/3;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3.1rem;
  /* border: 3px solid rgba(255, 255, 255, 0.5); */
  border-radius: calc(var(--radius) / 3);
  text-shadow: 0 0 15px #fff;
  overflow: hidden;
}

#OkeyCross {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
}

#OkeyFooter {
  grid-area: OkeyFooter;
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
}

#OkeyFooter > .actions {
  width: 2.5rem;
}

#OkeyCard.yellow {
  background: rgba(198, 174, 6, 1) url("./red.svg") center;
  background-size: cover;
}

#OkeyCard.blue {
  background: rgba(23, 93, 164, 1) url("./red.svg") center;
  background-size: cover;
}
#OkeyCard.red {
  background: rgba(181, 24, 23, 1) url("./red.svg") center;
  background-size: cover;
}

@media (min-width: 820px) {
  #OkeyScore > #OkeyCard {
    font-size: 11rem;
  }

  #OkeyScore > :last-child {
    font-size: 5rem;
  }

  #OkeyCard {
    font-size: 5rem;
  }
}

@media (min-width: 1366px) {
  #Okey {
    display: grid;
    grid-template-areas: "OkeyField OkeyCombinations";
    grid-template-columns: 6fr 3fr;
  }

  #OkeyCard,
  #OkeyScore > #OkeyCard {
    font-size: 8rem;
  }
  #OkeyScore > :last-child {
    font-size: 2.5rem;
  }
}
