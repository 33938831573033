#Projects {
  grid-area: projects;
  overflow-x: hidden;
}

#Project {
  margin-bottom: var(--margin);
}

#Project > span {
  white-space: pre-line;
}
