#MapRoute {
  background-repeat: repeat-y;
  display: grid;
  grid-template-columns: 2fr 5fr 3fr;
  grid-template-rows: 1fr 19fr 1fr;
  grid-template-areas:
    "UsernameInput ChannelList TableContainer"
    "Ronda MapContainer TableContainer"
    "Ronda Legenda TableContainer";
  gap: var(--gap);
  align-items: start;
  justify-items: center;
  height: 100%;
  padding: var(--padding);
  font-family: "PTSans", "Trebuchet MS", "Helvetica Neue", "Nimbus Sans", "Helvetica", "Arial", sans-serif;
  box-sizing: border-box;
}

#ChannelList {
  /* color: rgba(230, 230, 230, 1); */
  grid-area: ChannelList;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--metin2-white);
  border-radius: var(--radius);
}

#ChannelList > span {
  border-radius: var(--radius);
  padding: var(--padding) calc(var(--padding) * 3);
  margin: var(--margin) calc(var(--margin) * 3);
  cursor: pointer;
}

.selectedChannel {
  background-color: var(--metin2-red) !important;
  color: var(--metin2-font-color);
}

#MapContainer {
  grid-area: MapContainer;
  position: relative; /* Questo è fondamentale per il posizionamento assoluto dei pallini */
  width: 750px;
  height: 750px;
  align-self: center;
}

#MapImage {
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: var(--radius);
}

.marker {
  box-sizing: border-box;
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  opacity: 0.8;
  pointer-events: none;
}

#UsernameInput {
  box-sizing: border-box;
  grid-area: UsernameInput;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: var(--padding);
  border-radius: var(--radius);
  border: none;
  background-color: var(--metin2-white);
  color: var(--metin2-red);
}

#TableContainer {
  grid-area: TableContainer;
  overflow-y: auto;
  width: 100%;
  max-height: 100%;
  border-radius: var(--radius);
  background-color: var(--metin2-white);
  color: var(--metin2-red);
}

#TableContainer > h1 {
  text-align: center;
  padding: var(--padding);
  box-sizing: border-box;
  font-size: large;
}

#Coordinates,
#Ronda {
  border-collapse: collapse;
  width: 100%;
}

#Coordinates > thead > tr,
#Ronda > thead > tr {
  font-size: x-large;
  border-bottom: 5px solid black;
  text-align: center;
}

#Coordinates > tbody > tr,
#Ronda > tbody > tr {
  font-size: large;
  border-bottom: 1px solid black;
  cursor: pointer;
  text-align: center;
}

#Coordinates > tbody > tr > td:last-child > img {
  height: 10px;
  width: auto;
}

#Ronda {
  grid-area: Ronda;
  background-color: var(--metin2-white);
  color: var(--metin2-red);
  width: 100%;
  border-radius: var(--radius);
  overflow: hidden;
}

#Ronda > tbody > tr > :last-child {
  width: 100px;
}

#RoomNumber {
  position: absolute;
  color: red;
  text-shadow: 0px 0px 3px black, 0 0 3px black, 0 0 3px black;
  z-index: 900;
  font-size: xx-large;
}

#Legenda {
  grid-area: Legenda;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: calc(var(--gap) * 6);
  border-radius: var(--radius);
  padding: var(--padding);
  box-sizing: border-box;
  width: 100%;
  background-color: var(--metin2-white);
  color: var(--metin2-red);
}

#Legenda > * {
  font-size: x-large;
}
