#ExperienceSection {
  /* background-color: yellow; */
  /* color: var(--dark); */
  overflow-y: auto;
}

#SingleExperience {
  /* color: var(--dark); */
  display: grid;
  align-items: center;
  grid-template-columns: 2fr 8fr;
  grid-template-areas:
    "bullet ."
    "bullet ."
    "bullet ."
    "bullet ."
    "bullet .";
  margin-bottom: calc(var(--margin) * 2);
}

#SingleExperience > div {
  grid-area: bullet;
  justify-self: center;
  background-color: black;
  width: 0.8rem;
  aspect-ratio: 1;
  border-radius: 50%;
  position: relative;
}

.lineConnect {
  background-color: black;
  width: 2px;
  height: 120px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0);
}
