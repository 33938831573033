#HeaderSection {
  grid-area: headerSection;
  padding-top: calc(var(--padding) * 8) !important;
  position: relative;
}

#HeaderSection > img.profilePic {
  width: 30%;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 50%;
  border: calc(var(--padding) / 3) solid var(--clear);
  top: 0;
  transform: translate(0, -50%);
  position: absolute;
}
#HeaderSection > h3 {
  margin: calc(var(--margin) / 2) 0;
}

@media (min-width: 820px) {
  #HeaderSection > img.profilePic {
    width: 150px;
  }
}
