#ContactSection {
  grid-area: contactSection;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "title title"
    ". .";
  flex-direction: column;
  grid-gap: calc(var(--gap) * 3);
}

#ContactSection > h1 {
  grid-area: title;
}

#Contact {
  justify-self: center;
  width: 70%;
  display: flex;
  justify-content: center;
  aspect-ratio: 1;
}

#Contact > img {
  width: 100%;
  aspect-ratio: 1;
}

@media (min-width: 820px) {
  #ContactSection {
    grid-template-columns: repeat(8, 1fr);
    grid-template-areas:
      "title title title title title title title title"
      ". . . . . . . .";
    grid-gap: unset;
  }
  #ContactSection > h1 {
    margin-bottom: unset;
  }
}
