#PersonalDetails {
  background-color: var(--dark);
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  font-family: Segoe UI Historic, Segoe UI, Helvetica, Arial, sans-serif;
}

#PersonalDetails > div {
  padding-top: calc(var(--padding) * 15);
  padding-inline: var(--padding);
  overflow-y: scroll;
  position: relative;
  height: inherit;
  width: inherit;
  box-sizing: border-box;
}

#PersonalDetails > div::-webkit-scrollbar,
#PersonalDetails > div > div::-webkit-scrollbar {
  display: none;
}

#PersonalDetails > div > div > h1 {
  margin-bottom: calc(var(--margin) * 2);
}

#PersonalDetails > div > :first-child {
  border-radius: calc(var(--radius) / 2) calc(var(--radius) / 2) 0 0;
}
#PersonalDetails > div > :last-child {
  border-radius: 0 0 calc(var(--radius) / 2) calc(var(--radius) / 2);
}

#PersonalDetails > div > * {
  /* background-color: var(--clear); */
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  /* border: 1px solid white; */

  padding: var(--padding);
  margin-bottom: var(--margin);
}

#PersonalDetails > img.reactLogo {
  width: 80%;
  position: absolute;
  top: -70px;
  right: -60px;
  animation: rotation 60s infinite linear;
  background-color: unset;
}

@media (min-width: 820px) {
  #PersonalDetails > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 3fr 1fr;
    grid-gap: var(--gap);
    grid-template-areas:
      "headerSection projects"
      ". projects"
      "contactSection contactSection";
  }

  #PersonalDetails > div > *:not(:last-child) {
    margin-bottom: unset;
  }

  #PersonalDetails > div > :first-child {
    border-radius: unset;
  }
  #PersonalDetails > div > :last-child {
    border-radius: unset;
  }
}

@media (min-width: 1366px) {
  #PersonalDetails > div {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: unset;
    grid-gap: var(--gap);
    grid-template-areas:
      "headerSection contactSection contactSection"
      ". projects projects";
    padding-bottom: var(--padding);
  }

  #PersonalDetails > div > * {
    margin-bottom: unset;
  }
  #PersonalDetails > img.reactLogo {
    width: 40%;
  }
}
