html,
body,
#root,
#App {
  display: flex;
  flex-direction: column;
  margin: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

#root {
  background-color: var(--dark);
}

* {
  margin: 0;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

h1,
h3,
p {
  margin: 0;
}

:root {
  --dark: rgba(30, 30, 30, 1);
  --clear: rgba(255, 255, 255, 0.95);
  --radius: 0.5rem;
  --padding: 0.5rem;
  --margin: 0.5rem;
  --shadow: 0 0 1rem 0.3rem rgba(0, 0, 0, 0.5);
  --gap: 0.5rem;

  --metin2-background: rgba(242, 231, 209, 1);

  --metin2-red: rgba(105, 5, 5, 1);
  --metin2-red-clear: rgba(105, 5, 5, 0.7);
  --metin2-white: rgba(239, 214, 173, 1);
  --metin2-font-color: rgba(239, 214, 173, 1);
}

h1 {
  font-size: 2rem;
}
h2 {
  font-size: 1.2rem;
}
h3 {
  font-size: 0.8rem;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
